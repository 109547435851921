<template>
  <div class="page">
    <div v-for="(item, index) in list" :key="index">
      <div id="now" ref="now" v-if="item[0].openDate == now_date"></div>
      <div class="month" v-if="item.length !== 0">
        <div class="in_box">{{ monthTabForMat(item[0].openDate) }}月</div>
      </div>
      <div class="in_item" v-for="(j, index2) in item" :key="index2">
        <div class="txt">
          <span>{{ j.trainType }}</span>
          {{ j.name }}
        </div>
        <div class="time">上课时间：{{ j.openTime }}</div>
      </div>
    </div>
    <div class="to_top" @click="to_now()">
      <img src="./img/toTop.png" class="toTopIcon" />
    </div>
  </div>
</template>

<script>
import { stringify } from "querystring";
import { getInitPageNoUrl, getCpbCourseListUrl } from "./api.js";
import moment from "moment";
export default {
  name: "classScheduleCard",
  data() {
    return {
      list: [],
      now_date: "",
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    var myDate = new Date();
    var month = myDate.getMonth();
    var year = myDate.getFullYear();
    this.now_date = `${String(year)}-${
      (month + 1).length === 1 ? String(month + 1) : "0" + String(month + 1)
    }`;
    console.log(this.now_date);
  },
  mounted() {
    this.getList();
  },
  methods: {
    to_now() {
      console.log(document.getElementById("now"));
      console.log(this.$refs.now);
      document.getElementById("now").scrollIntoView();
    },
    // 获取课程列表
    async getList() {
      let params = {
        fromFlag: 1,
      };
      let res = await this.$axios.get(`${getCpbCourseListUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        let data = res.data.records;
        console.log(res);
        data.forEach((item, index) => {
          item.openTime = item.openDate;
          item.openDate = item.openDate.slice(0, 7);
        });
        let data2 = this.sortArr(data, "openDate");
        console.log(data2);
        this.list = data2;
        this.$nextTick(() => {
          document.getElementById("now").scrollIntoView();
        });
      }
    },
    monthTabForMat(str) {
      return str.replace("-", "年");
    },
    sortArr(arr, str) {
      //数组格式化
      let _arr = [],
        _t = [],
        // 临时变量
        _tmp;

      // 有相同值的排在一起
      arr = arr.sort(function (a, b) {
        let s = a[str],
          t = b[str];

        return s < t ? -1 : 1;
      });
      console.log(arr);
      if (arr.length) {
        _tmp = arr[0][str];
      }
      console.log(arr);
      // 将有相同类别的对象添加到统一个数组
      for (let i in arr) {
        if (arr[i][str] === _tmp) {
          _t.push(arr[i]);
        } else {
          _tmp = arr[i][str];
          _arr.push(_t);
          _t = [arr[i]];
        }
      }
      _arr.push(_t);
      console.log(_arr); //格式化后数组
      return _arr;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  // height: auto;
  background-color: #fff;

  // margin-bottom: 54px;
  .to_top {
    z-index: 100;
    position: fixed;
    right: 18px;
    bottom: 250px;
    width: 130px;
    height: 130px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 50%;

    .toTopIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 58px;
    }
  }

  .in_item {
    width: 690px;
    height: 190px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    margin: 0 auto;
    margin-bottom: 54px;
    padding: 28px 18px 0;
    box-sizing: border-box;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    .time {
      margin-top: 24px;
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }

    .txt {
      font-size: 32px;
      color: #000;
      font-weight: 400;
      overflow: hidden;
      line-height: 40px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      span {
        padding: 2px 5px;
        font-size: 24px;
        font-weight: 600;
        color: #ff853a;
        border-radius: 8px;
        border: 2.5px solid #ff853a;
        box-sizing: border-box;
      }
    }
  }

  .month {
    height: 126px;
    width: 100%;
    background-color: #fff;
    position: relative;
    margin: 6px 0;
    .in_box {
      width: 180px;
      height: 48px;
      background: #ff853a;
      border-radius: 6px;
      text-align: center;
      line-height: 48px;
      font-size: 28px;
      color: #fff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
    }
  }
}
</style>
